<template>
  <div class="app-body">
    <SideBar
      v-show="getStatusBar"
      class="sidebar"
      @closeSideBar="closeSideBar()"
    />
    <OnboardCarousel v-show="getShowOnboard" />
    <loader-component
      v-show="getLoaderScreen"
      @closeSideBar="closeLoaderScreen()"
    />
    <router-view> </router-view>
    <div>
      <!-- <Login /> -->
    </div>
    <!-- <pulse-loader :loading="true" :color="'# f4f4f4'" :size="'10'"></pulse-loader> -->
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import PullToRefresh from "pulltorefreshjs";
import socket from "./socket";
import OnboardCarousel from "./components/OnboardCarousel.vue";

export default {
  components: { OnboardCarousel },
  data() {
    return {
      showTopNavBar: false,
      showBottomNavBar: false,
      userData: undefined,
      qrTableId: null,
      topNavbarType: "",
      topNavbarData: {},
      bottomNavbarType: "",
      partnerName: "",
      showSideBar: false,
      navView: "",
    };
  },
  setup() {
    const store = useStore();
    let showStatusBar = computed(function () {
      return store.state.showStatusBar;
    });

    let showOnboard = computed(function () {
      return store.state.showOnboard;
    });

    let showLoaderScreen = computed(function () {
      return store.state.showLoaderScreen;
    });

    return {
      showStatusBar,
      showLoaderScreen,
      showOnboard,
    };
  },
  created() {
    window.addEventListener("storage", async () => {
      if (this.$route.name !== "Login") {
        let userData = JSON.parse(await localStorage.getItem("userData"));
        if (!userData) {
          // this.$router.replace("/login");
        } else {
          if (!socket.connected && this.hasAccessRight("RECIEVE_ORDER")) {
            socket.connect();
          }
        }
      }
    });

    PullToRefresh.init({
      mainElement: "body",
      onRefresh() {
        window.location.reload();
      },
      distThreshold: 75,
    });

    socket.on("acceptOrderList", (message) => {
      this.$store.dispatch("setRestaurantSideOrderList", message);
    });
  },
  beforeCreate() {},
  computed: {
    getStatusBar() {
      return this.showStatusBar;
    },
    getLoaderScreen() {
      return this.showLoaderScreen;
    },
    getShowOnboard() {
      return this.showOnboard;
    },
  },
  methods: {
    closeSideBar() {
      return false;
    },
  },
  async mounted() {
    const userData = await window.localStorage.getItem("userData");
    if (userData) {
      this.userData = userData;
    }
  },
  watch: {
    user: function () {},
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
// @font-face {
//   font-family: 'Zilla Slab';
//   src: url('./fonts/ZillaSlab-Regular.ttf') format('truetype');
// }

.gm-style-cc,
.gm-svpc,
.gm-control-active,
.gmnoprint,
.gm-style-cc-k {
  display: none !important;
}

.widget-scene-canvas {
  display: none !important;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.swal2-container {
  // z-index: 9999;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background-color: #f5f6fb;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "Roboto", serif !important;
}

p {
  font-family: "Roboto", serif !important;
  color: #2e2e28;
  font-size: 12px;
  margin: 0px;
}

h3 {
  font-size: 14px;
}

h2 {
  font-size: 16px;
}

h1 {
  font-size: 18px;
}

.solid {
  color: #000000 !important;
}

button {
  // font-family: "Roboto", serif !important;
}

div {
  // font-family: "Roboto", serif !important;
}

.root-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  justify-content: space-between;
}

.first-line {
  background-color: blue;
}

.static-container {
  // height: calc(100%-200px);
  // overflow: auto;
}

.app-container-body {
  // height: calc(100%-200px);
  // overflow: auto;
  flex: 1;
}

.bottom-nav {
  position: sticky;
  bottom: 0px;
  width: 100%;
}

// .binded-class-search {
//   flex: auto,
// }

.swal2-container {
  z-index: 5002 !important;
}
</style>
