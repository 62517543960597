<template>
  <!-- <div> -->
  <!-- <div class="frame"> -->
  <div class="center">
    <div v-if="count === 0" class="component">
      <div id="minus" @click="editItemCount('minus')">-</div>
      <div id="counter" :stylle="{ display: 'flex' }">Add</div>
      <div id="plus" @click="editItemCount('plus')">+</div>
    </div>
    <div v-else class="component">
      <div id="minus" @click="editItemCount('minus')">-</div>
      <div id="counter" :stylle="{ display: 'flex' }">
        {{ count }}
      </div>
      <div id="plus" @click="editItemCount('plus')">+</div>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    multiple: { type: Number, default: 1 },
    count: { type: Number, default: 0 },
  },
  methods: {
    editItemCount(action) {
      if (action === "plus") {
        this.$emit("edited-value", {
          count: this.count + this.multiple,
          action: "PLUS",
        });
      } else if (action === "minus") {
        this.$emit("edited-value", {
          count: this.count - this.multiple,
          action: "MINUS",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.frame {
  border-radius: 2px;
  color: #333;
  margin-top: 13px;
  font-family: "Open Sans", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center {
  //   display: block;
  width: max-content;
}

.component {
  border: 1px solid black;
  padding: 2px;
  border-radius: 5px;
  display: flex;
  // width: 120px;
}

#plus,
#minus,
#counter {
  padding: 7px;
  // width: 100px;
  // font-size: 25px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

#counter {
  text-align: center;
  width: 70px;
}
</style>
