import axios from "axios";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const baseUrl = process.env.VUE_APP_API_URL;

const apiMixin = {
  data() {
    return {};
  },
  methods: {
    async getApiCall(url, data, useOffLoaderScreen) {
      try {
        if (useOffLoaderScreen === false) {
          // No change of loader
        } else {
          this.$store.dispatch("setLoaderScreen", true);
        }

        let userData = JSON.parse(localStorage.getItem("userData"));
        let response = await axios.get(`${baseUrl}/api${url}`, {
          params: data,
          headers: { "auth-token": userData && userData.authToken },
        });
        this.$store.dispatch("setLoaderScreen", false);
        return response.data.msg;
      } catch (e) {
        this.$store.dispatch("setLoaderScreen", false);
        this.errorAlert(e.response.data.message);
        return;
      }
    },
    async postApiCall(url, data) {
      try {
        this.$store.dispatch("setLoaderScreen", true);
        let userData = JSON.parse(localStorage.getItem("userData"));
        let response = await axios.post(`${baseUrl}/api${url}`, data, {
          headers: { "auth-token": userData && userData.authToken },
        });
        this.$store.dispatch("setLoaderScreen", false);
        return response.data.msg;
      } catch (e) {
        this.$store.dispatch("setLoaderScreen", false);
        this.errorAlert(e.response.data.message);
        return;
      }
    },
    async uploadFile(url, data) {
      try {
        this.$store.dispatch("setLoaderScreen", true);
        let userData = JSON.parse(localStorage.getItem("userData"));
        let response = await axios.post(`${baseUrl}/api${url}`, data, {
          headers: {
            "auth-token": userData && userData.authToken,
            "Content-Type": "multipart/form-data",
          },
        });
        this.$store.dispatch("setLoaderScreen", false);
        return response.data.msg;
      } catch (e) {
        this.$store.dispatch("setLoaderScreen", false);
        this.errorAlert(e.response.data.message);
        return;
      }
    },

    async uploadFileCall(url, data, file) {
      try {
        const formData = new FormData();
        formData.append("file", file, "resized_image.jpg"); // Append the resized image
        let dataKeys = Object.getOwnPropertyNames(data);
        dataKeys.map((key) => {
          formData.append(key, data[key]);
        });
        let userData = JSON.parse(localStorage.getItem("userData"));
        let response = await axios.post(`${baseUrl}/api${url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": userData && userData.authToken,
          },
          onUploadProgress: function (progressEvent) {
            // eslint-disable-next-line no-unused-vars
            const percentComplete = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          },
        });
        return response.data.msg;
      } catch (e) {
        this.$store.dispatch("setLoaderScreen", false);
        this.errorAlert(e);
        return;
      }
    },
  },
  created() {},
};

export default apiMixin;
