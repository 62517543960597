<template>
  <div>
    <top-navbar
      :topNavbarType="['sidebar', 'app-name']"
      :topNavbarData="{ title: 'Order List' }"
    ></top-navbar>
    <div class="my-order-container">
      <div
        v-for="order in myOrders"
        @click="goSomewhere(order)"
        :key="order._id"
        :class="['my-order-cards']"
      >
        <div :style="{ display: 'flex' }">
          <div>
            <image-comp
              :imageUrl="order.restaurantInfo.image"
              :classProp="['prev-order-restaurant-img']"
            />
          </div>
          <div :style="{ marginLeft: '5px' }">
            <div>{{ order.restaurantInfo.name }}</div>
            <div>{{ order.restaurantInfo.address }}</div>
          </div>
        </div>
        <div class="my-order-card">
          <div
            v-for="items in order.order"
            :key="items._id"
            :style="{ marginTop: '7px' }"
          >
            <div class="menu-count">
              <p class="count">{{ `${items.count} x ${items.itemName}` }}</p>
            </div>
          </div>
          <div :style="{ marginTop: '7px' }">
            <div v-if="order.status === 'ORDER_ACCEPTED'">
              <p>Order Accepted by Restaurant!</p>
            </div>
            <div v-if="order.status === 'ORDER_WAITING'">
              <p>Waiting... for Restaurant to accept order!</p>
            </div>
            <div v-if="order.status === 'PREPARATION_STARTED'">
              <p>Preparing....</p>
            </div>
            <div v-if="order.status === 'PREPARATION_DONE'">
              <p>Preparation Done....</p>
            </div>
            <div v-if="order.status === 'ORDER_REJECTED'">
              <p>Order has been rejected!</p>
            </div>
          </div>
          <p class="datetime" :style="{ marginTop: '7px' }">
            {{ getDateTime(order.createdOn) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  data() {
    return {
      counter: 0,
      myOrders: [],
    };
  },
  async created() {
    let result = await this.getApiCall("/delivery/getMyOrders");
    this.myOrders = result;
  },
  methods: {
    goSomewhere(order) {
      this.$router.push(`/my-orders-detail/${order._id}`);
    },
    getDateTime(datetime) {
      return moment(datetime).format("LLL");
    },
    showSideMenu() {
      this.$store.dispatch("setStatusBar", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-order-container {
  margin: 16px 16px 0px 16px;

  .menu-icon {
    width: 40px;
  }

  .my-order-cards {
    background-color: white;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 5px;

    .my-order-card {
      .restaurant-name {
        // font-size: 20px;
      }

      .table-name {
        // font-size: 15px;
      }

      .otp {
        // font-size: 10px;

        .otp-value {
          // font-size: 30px;
        }
      }
    }
  }

  .my-order-active {
    background-color: #ccffe6;
  }
}
</style>
