<template>
  <div class="upload-container">
    <h1>Upload an Image</h1>
    <input type="file" @change="onFileChange" accept="image/*" />
    <button @click="uploadImage">Upload Image</button>
    <p v-if="message">{{ message }}</p>
    <img v-if="uploadedImage" :src="uploadedImage" alt="Uploaded Image" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageFile: null,
      message: "",
      uploadedImage: "",
    };
  },
  methods: {
    onFileChange(event) {
      this.imageFile = event.target.files[0];
    },
    async uploadImage() {
      if (!this.imageFile) {
        this.message = "No file selected";
        return;
      }

      // Validate file size (less than 100KB)
      if (this.imageFile.size > 100000) {
        this.message = "File size must be less than 100KB";
        return;
      }

      // Validate aspect ratio (2:1)
      const image = new Image();
      image.src = URL.createObjectURL(this.imageFile);

      image.onload = async () => {
        // const width = image.width;
        // const height = image.height;

        // if (width / height !== 2) {
        //   this.message = 'The image must have a 2:1 aspect ratio (width:height)';
        //   return;
        // }

        // Proceed to upload if validations pass
        const formData = new FormData();
        formData.append("image", this.imageFile);

        try {
          await this.uploadFile("/superAdmin/uploadBanner", formData);
        } catch (error) {
          this.message = "An error occurred while uploading the image";
          console.error(error);
        }
      };
    },
  },
};
</script>

<style scoped>
.upload-container {
  text-align: center;
  margin-top: 50px;
}
input[type="file"] {
  margin-bottom: 10px;
}
button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}
button:hover {
  background-color: #45a049;
}
img {
  margin-top: 20px;
  max-width: 100%;
}
</style>
