<template>
  <div :style="{ width: '100%' }" class="restaurant-card-container">
    <div
      :class="[
        'restaurant-card',
        restaurant.status === 'OFFLINE' ? 'show-offline' : '',
      ]"
      @click="gotoMenu()"
    >
      <image-comp
        :imageUrl="restaurant.image"
        :classProp="['restaurant-image']"
      />
      <div :class="['overlay-text']">
        <div :style="{ marginBottom: 'auto', marginLeft: 'auto' }">
          <div
            class="rating"
            :style="{
              border: '1px solid #00ff00',
              width: '80px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: '5px',
            }"
          >
            <img
              src="/images/star-rating.svg"
              :style="{
                width: '13.9px',
                height: '13.5px',
                margin: '5px',
              }"
            />
            <p :style="{ weight: '600', color: '#00ff00' }">
              {{ restaurant.rating ? restaurant.rating : "-" }}
            </p>
          </div>
        </div>
        <div :style="{ display: 'flex', justifyContent: 'space-between' }">
          <div :style="{ display: 'flex' }">
            <div
              :style="{
                display: 'flex',
                backgroundColor: 'white',
                padding: '2px 15px',
                borderRadius: '20px',
                border: '1px solid #D76E19',
                alignItems: 'center',
              }"
            >
              <img src="/images/delivery-time.svg" />
              <p :style="{ padding: '0px 5px' }">{{ duration }}</p>
              <p>•</p>
              <p :style="{ paddingLeft: '5px' }">{{ distance }}</p>
            </div>
          </div>
          <div :style="{ display: 'flex' }">
            <div
              :style="{
                display: 'flex',
                backgroundColor: 'white',
                padding: '2px 15px',
                borderRadius: '20px',
                border: '1px solid #D76E19',
                alignItems: 'center',
              }"
            >
              <img
                src="/images/love-sign.svg"
                :style="{ paddingRight: '8px' }"
              />
              <img src="/images/eye-sign.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :style="{ padding: '8px' }">
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }"
      >
        <h1 class="restaurant-name">{{ restaurant.name }}</h1>
        <img
          v-if="resTypeIcon === 'veg'"
          src="https://tumyto-prod-bucket.s3.ap-south-1.amazonaws.com/veg-icon.png"
          :style="{
            margin: '5px',
          }"
        />
        <img
          v-if="resTypeIcon === 'nonveg'"
          src="https://tumyto-prod-bucket.s3.ap-south-1.amazonaws.com/nonveg-icon.png"
          :style="{
            margin: '5px',
          }"
        />
      </div>
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }"
      >
        <p class="restaurant-cuisine">
          {{ restaurant.cuisineType }}
        </p>
        <p class="restaurant-eta">₹ {{ restaurant.avgPrice }} for one</p>
      </div>
      <div v-if="restaurant.discounttext">
        <svg width="100%" height="24">
          <line
            x1="0"
            y1="16"
            x2="390"
            y2="16"
            stroke="black"
            stroke-width="1"
            stroke-dasharray="5,5"
          />
        </svg>
        <div
          :style="{
            display: 'flex',
            alignItems: 'center',
          }"
        >
          <img src="images/coupon.svg" :style="{ marginRight: '4px' }" />
          <p class="restaurant-cuisine">
            {{ restaurant.discounttext }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  data() {
    return {
      distance: "",
      duration: "",
      resTypeIcon: "",
      distanceData: {},
    };
  },
  props: {
    restaurant: {
      type: Object,
      required: true,
    },
    gotoUrl: {
      type: String,
      required: true,
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    distanceData(newValue, oldValue) {
      this.$emit("distanceMeter", this.distanceData.value);
    },
  },
  async created() {
    if (this.restaurant.tags.includes("veg")) {
      this.resTypeIcon = "veg";
    } else if (this.restaurant.tags.includes("nonveg")) {
      this.resTypeIcon = "nonveg";
    }
    await this.getRestaurantDistance(this, this.restaurant);
    this.beautifyDuration();
  },
  methods: {
    beautifyDuration(seconds) {
      // Create a duration object from seconds
      var duration = moment.duration(seconds, "seconds");

      // Extract hours, minutes, and seconds
      var hrs = duration.hours();
      var mins = duration.minutes();

      // Build the beautified string
      var parts = [];
      if (hrs > 0) parts.push(hrs + " hrs");
      if (mins > 0) parts.push(mins + " mins");
      return parts.join(" ");
    },
    gotoMenu() {
      if (this.restaurant.status === "ONLINE") {
        this.$router.push(this.gotoUrl);
      } else {
        this.errorAlert("Restaurant is closed right now.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.restaurant-card-container {
  background-color: white;
  margin-bottom: 16px;
  border-radius: 5px;
}

.show-offline {
  filter: grayscale(100%);
}

.restaurant-card {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  // background-color: white;
  overflow: hidden;
  // margin-bottom: 16px;
  position: relative;
  display: inline-block;
}

.restaurant-image {
  width: 100%;
  // height: 100px;
  object-fit: cover;
}

.restaurant-info {
  padding: 12px;
}

.restaurant-name {
  margin: 0;
  // font-size: 20px;
  font-weight: 500;
}

.overlay-text {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  /* transform: translate(-50%, -50%); */
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // align-items: flex-end;

  .restaurant-cuisine,
  .restaurant-address {
    // margin: 6px 0;
    // font-size: 14px;
    color: #ffffff;
  }

  .restaurant-eta {
    // margin: 6px 0;
    // font-size: 14px;
    color: #ffffff;
  }

  .restaurant-ratings {
    display: flex;
    align-items: center;
  }

  .restaurant-rating {
    margin: 0;
    // font-size: 16px;
    font-weight: bold;
    color: #ff6b6b;
  }

  .restaurant-votes {
    margin-left: 4px;
    // font-size: 14px;
    color: #666;
  }
}

.overlay-text.inactive {
  background-color: rgba(255, 255, 255, 0.6) !important;
}
</style>
