import Swal from "sweetalert2";
import moment from "moment-timezone";

const utilityMixin = {
  data() {
    return {
      myValue: "",
      profileImage: "",
    };
  },
  methods: {
    getUserName() {
      let userData = JSON.parse(localStorage.getItem("userData"));
      let r = `/images/profile-letters/${this.getFirstAlphabetCharacter(
        userData.username
      )}.png`;
      this.profileImage = r;
      return userData?.username;
    },
    hasEntitlement(entitlement) {
      let restaurantData = window.localStorage.getItem("restaurantData");
      return !!(
        restaurantData &&
        restaurantData.entitlements &&
        restaurantData.entitlements.includes(entitlement)
      );
    },
    momentParse(timeStamp, format) {
      return moment(timeStamp).format(format);
    },
    hasAccessRight(accessRight) {
      let userData = JSON.parse(localStorage.getItem("userData"));
      let accessRights = userData?.accessRights;
      return accessRights?.includes(accessRight);
    },
    errorAlert(errText) {
      Swal.fire({
        text: errText || "Error: Contact Support",
        target: "#custom-target",
        toast: true,
        position: "bottom",
        showConfirmButton: false,
        timer: 3000,
        color: "#ffffff",
        background: "crimson",
        padding: "5px",
        margin: "5px",
      });
    },
    infoAlert(infoText) {
      Swal.fire({
        text: infoText,
        target: "#custom-target",
        toast: true,
        position: "bottom",
        showConfirmButton: false,
        timer: 3000,
        color: "#ffffff",
        background: "#009933",
        padding: "5px",
      });
    },
    async getRestaurantDistance(self, restaurant) {
      function callback(data) {
        (self.distanceData = data.rows[0].elements[0].distance),
          (self.restaurantAddress = data.originAddresses[0].split(","));
        self.dropAddress = data.destinationAddresses[0].split(",");
        self.distance = data.rows[0].elements[0].distance.text;
        self.duration = self.beautifyDuration(
          data.rows[0].elements[0].duration.value + 900
        );
        return;
      }
      try {
        let origin = {
          lat: restaurant.location.coordinates[0],
          lng: restaurant.location.coordinates[1],
        };
        let currentAddress = await localStorage.getItem("addressData");
        const addressData = JSON.parse(currentAddress);
        let destination;

        if (!addressData) {
          // Check if the Permissions API is supported
          navigator.geolocation.getCurrentPosition(async (position) => {
            if (position.coords.latitude) {
              destination = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              var service = new window.google.maps.DistanceMatrixService();
              service.getDistanceMatrix(
                {
                  origins: [origin],
                  destinations: [destination],
                  travelMode: "DRIVING",
                },
                callback
              );
            }
          });
        } else {
          destination = {
            lat: addressData.location.coordinates[0],
            lng: addressData.location.coordinates[1],
          };

          var service = new window.google.maps.DistanceMatrixService();
          service.getDistanceMatrix(
            {
              origins: [origin],
              destinations: [destination],
              travelMode: "DRIVING",
            },
            callback
          );
          // const self = this;
        }
      } catch (e) {
        console.log(e);
      }
    },
    getAddress() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const geocoder = new window.google.maps.Geocoder();

          geocoder.geocode(
            {
              location: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            },
            (results, status) => {
              if (status === "OK") {
                if (results[0]) {
                  return resolve({
                    add1: results[0].formatted_address.split(",")[1],
                    add2: results[0].formatted_address.split(",")[2],
                  });
                } else {
                  return reject();
                }
              } else {
                return reject();
              }
            }
          );
        });
      });
    },
  },
  created() {},
};

export default utilityMixin;
