<template>
  <div>
    <top-navbar
      :topNavbarType="['sidebar', 'toggle-status-button']"
      :topNavbarData="{ title: 'Accept Orders' }"
    ></top-navbar>

    <div>{{ restaurantName }}</div>
    <div class="horizontal-pane">
      <div
        v-if="hasAccessRight('RECIEVE_ORDER')"
        @click="
          () => {
            whichSectionToShow('INCOMING_ORDER');
          }
        "
        :class="{ option: true, 'horizontal-pane-active': showIncomingOrder }"
      >
        <p>Incoming Orders</p>
      </div>
      <div
        v-if="hasAccessRight('CHANGE_ORDER_STATUS')"
        @click="
          () => {
            whichSectionToShow('ACCEPTED_ORDER');
          }
        "
        :class="{ option: true, 'horizontal-pane-active': showAcceptOrder }"
      >
        <p>Accepted Orders</p>
      </div>
      <div
        v-if="hasAccessRight('VIEW_ORDER_HISTORY')"
        @click="
          () => {
            whichSectionToShow('PREPARED_ORDER');
          }
        "
        :class="{ option: true, 'horizontal-pane-active': showPreparedOrder }"
      >
        <p>Prepared Orders</p>
      </div>
    </div>
    <div v-if="showIncomingOrder" class="order-list-container">
      <div class="chef-order-list-container">
        <div v-if="showList">
          <div
            v-for="(order, index) in getIncomingOrderList()"
            :key="order._id"
          >
            <div :style="{ display: 'flex' }">
              Order Id: &nbsp;&nbsp;
              <p :style="{ fontSize: '20px' }">{{ order.externalOrderId }}</p>
            </div>
            <div :style="{ marginTop: '7px' }">
              <item-list-component :order="order"></item-list-component>
            </div>
            <div :style="{ marginTop: '7px' }">
              {{ this.momentParse(order.createdOn, "LLLL") }}
            </div>
            <div>
              <counter-edit
                :multiple="5"
                :count="getThisValue(index)"
                @editedValue="editedCount($event, index)"
              ></counter-edit
              ><span>mins</span>
            </div>
            <div class="display-flex">
              <div class="button-div">
                <button-comp
                  :value="'Accept'"
                  class="add-location-button"
                  :id="'getOtpButton'"
                  :height="'40px'"
                  :width="'100%'"
                  :color="'#7FBD4F'"
                  @clickEvent="submitAction('ACCEPT', index)"
                ></button-comp>
              </div>
              <div class="button-div">
                <button-comp
                  :value="'Reject'"
                  class="add-location-button"
                  :id="'getOtpButton'"
                  :height="'40px'"
                  :width="'100%'"
                  :color="'crimson'"
                  :textToShow="'Reject Order ?'"
                  @clickEvent="submitAction('REJECT', index)"
                ></button-comp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAcceptOrder" class="order-list-container">
      <div class="chef-order-list-container">
        <div v-if="showList">
          <div v-for="order in getAcceptedOrderList()" :key="order._id">
            <div :style="{ display: 'flex' }">
              Order Id: &nbsp;&nbsp;
              <p :style="{ fontSize: '20px' }">{{ order.externalOrderId }}</p>
            </div>
            <div>
              {{ order.kotNumber }}
            </div>
            <div>
              <item-list-component :order="order"></item-list-component>
            </div>
            <div class="display-flex">
              <div v-if="order.status === 'ACCEPTED'" class="button-div">
                <button-comp
                  :value="'Start Prep'"
                  class="add-location-button"
                  :id="'getOtpButton'"
                  :height="'40px'"
                  :width="'100%'"
                  :color="'#7FBD4F'"
                  :showConfirmationModal="true"
                  :textToShow="'Starting Preparation ?'"
                  @clickEvent="
                    changeOrderPrepStatus(order._id, 'PREPARATION_STARTED')
                  "
                ></button-comp>
              </div>
              <div
                v-if="order.status === 'PREPARATION_STARTED'"
                class="button-div"
              >
                <button-comp
                  :value="'Done Prep'"
                  class="add-location-button"
                  :id="'getOtpButton'"
                  :height="'40px'"
                  :width="'100%'"
                  :color="'crimson'"
                  :showConfirmationModal="true"
                  :textToShow="'Finish Preparation?'"
                  @clickEvent="
                    changeOrderPrepStatus(order._id, 'PREPARATION_DONE')
                  "
                ></button-comp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showPreparedOrder" class="order-list-container">
      <div class="chef-order-list-container">
        <div v-if="showList">
          <div v-for="order in getPreparedOrderList()" :key="order._id">
            <div :style="{ display: 'flex', justifyItems: 'center' }">
              <p>Order Id:</p>
              &nbsp;&nbsp;
              <p :style="{ fontSize: '20px' }">{{ order.externalOrderId }}</p>
            </div>
            <div>
              {{ order.kotNumber }}
            </div>
            <div>
              <item-list-component :order="order"></item-list-component>
            </div>
            <div class="display-flex">
              <div class="button-div">
                <button-comp
                  :value="'Order Dispatched'"
                  class="add-location-button"
                  :id="'getOtpButton'"
                  :height="'40px'"
                  :width="'100%'"
                  :color="'#7FBD4F'"
                  :showConfirmationModal="true"
                  :textToShow="'Recieved By Delivery Guy ?'"
                  @clickEvent="changeOrderPrepStatus(order._id, 'DISPATCHED')"
                ></button-comp>
              </div>
              <div class="button-div">
                <button-comp
                  :value="'Cancel'"
                  class="add-location-button"
                  :id="'getOtpButton'"
                  :height="'40px'"
                  :width="'100%'"
                  :color="'crimson'"
                  :showConfirmationModal="true"
                  :textToShow="'Reject Delivery ?'"
                  @clickEvent="
                    changeOrderPrepStatus(order._id, 'DISPATCH_DENIED')
                  "
                ></button-comp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavbar from "../../components/partials/TopNavbar.vue";
import socket from "../../socket";

let audio;
export default {
  components: { TopNavbar },
  data() {
    return {
      incomingOrderList: [],
      acceptedOrderList: [],
      preparedOrderList: [],
      showList: true,
      showFilter: false,
      startDate: new Date(),
      socket,
      showIncomingOrder: false,
      showAcceptOrder: false,
      showPreparedOrder: false,
      restaurantName: "",
    };
  },

  async mounted() {
    let currentSection = this.$route.params.currentSection;
    this.whichSectionToShow(currentSection);

    this.fetchData();
    setInterval(this.fetchData, 10000);
    // this.incomingOrderList = this.$store.state.incomingOrderList;
    // this.acceptedOrderList = this.$store.state.acceptedOrderList;
    // this.preparedOrderList = this.$store.state.preparedOrderList;

    this.getActiveOrderList();

    if (this.hasAccessRight("RECIEVE_ORDER")) {
      this.showIncomingOrder = true;
    } else if (this.hasAccessRight("CHANGE_ORDER_STATUS")) {
      this.showAcceptOrder = true;
    }
  },
  watch: {
    incomingOrderList() {
      return this.$store.state.incomingOrderList;
    },
    acceptedOrderList() {
      return this.$store.state.acceptedOrderList;
    },
    preparedOrderList() {
      return this.$store.state.preparedOrderList;
    },
  },
  computed: {
    getThisValue() {
      return (index) => {
        return this.incomingOrderList[index].estimatedPreparationTime;
      };
    },
    getIncomingOrderList() {
      return () => {
        return this.incomingOrderList;
      };
    },
    getAcceptedOrderList() {
      return () => {
        return this.acceptedOrderList;
      };
    },
    getPreparedOrderList() {
      return () => {
        return this.preparedOrderList;
      };
    },
  },
  methods: {
    whichSectionToShow(sectionType) {
      window.history.pushState(
        { path: `#/accept-delivery-order/${sectionType}` },
        "",
        `#/accept-delivery-order/${sectionType}`
      );
      if (sectionType === "INCOMING_ORDER") {
        this.showIncomingOrder = true;
        this.showAcceptOrder = false;
        this.showPreparedOrder = false;
      } else if (sectionType === "ACCEPTED_ORDER") {
        this.showIncomingOrder = false;
        this.showAcceptOrder = true;
        this.showPreparedOrder = false;
      } else if (sectionType === "PREPARED_ORDER") {
        this.showIncomingOrder = false;
        this.showAcceptOrder = false;
        this.showPreparedOrder = true;
      }
    },
    async getActiveOrderList() {
      let restaurantDetail = await this.getApiCall(
        "/restaurant/getStatusOrderList"
      );
      this.restaurantName = restaurantDetail.name;
    },
    getStartDate() {
      return new Date();
    },
    async changeOrderPrepStatus(orderId, status) {
      await this.postApiCall("/restaurant/setPrepStatus", { orderId, status });
      window.location.reload();
    },
    playAudio() {
      audio = new Audio("./images/buzzer.mp3");
      audio.currentTime = 0;
      audio.loop = true;
      audio.play();
    },
    editedCount(a, b) {
      this.incomingOrderList[b].estimatedPreparationTime = a.count;
    },
    async submitAction(actionType, index) {
      let setOrderData = this.incomingOrderList[index];
      let result;
      if (actionType === "REJECT") {
        result = await this.postApiCall("/restaurant/rejectIncomingOrder", {
          incomingOrderId: setOrderData._id,
        });
        window.location.reload();
      } else if (actionType === "ACCEPT") {
        if (
          setOrderData.estimatedPreparationTime &&
          setOrderData.estimatedPreparationTime !== 0
        ) {
          result = await this.postApiCall("/restaurant/acceptIncomingOrder", {
            incomingOrderId: setOrderData._id,
            estimatedPreparationTime: setOrderData.estimatedPreparationTime,
          });
          window.location.reload();
        }
      }
      if (result === "ORDER_REJECTED") {
        this.infoAlert("Order Rejected");
      } else if (result === "ORDER_ACCEPTED") {
        this.infoAlert("Order Accepted");
      } else if (result === "ORDER_ALREADY_ACCEPTED") {
        this.infoAlert("Order Already Accepted");
      } else if (
        setOrderData &&
        (!setOrderData.estimatedPreparationTime ||
          setOrderData.estimatedPreparationTime === 0)
      ) {
        this.errorAlert("Please enter estimated time of preparation");
      } else {
        this.errorAlert("Something went wrong");
      }
    },
    async fetchData() {
      const response = await this.getApiCall(
        "/restaurant/autoApiInterval",
        {},
        false
      );
      this.incomingOrderList = response.incomingOrderList;
      this.acceptedOrderList = response.acceptedOrderList;
      this.preparedOrderList = response.preparedOrderList;
    },
  },
};
</script>

<style lang="scss" scoped>
.chef-order-list-container {
  margin: 16px 16px 0px 16px;
  // background: #e4e4e4;
}

.button-div {
  margin: 16px 0px 16px 0px;
  width: calc(50% - 5px);
}

.horizontal-pane {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #f0f0f0;
  // padding: 10px;
}

.option {
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e4e4e4;
  // border-radius: 4px;
  // margin: 0 5px;
  cursor: pointer;
  display: flex;

  p {
    color: #000000;
    justify-content: center;
    max-width: fit-content;
    margin: 0px 7px;
  }
}

.horizontal-pane-active {
  background-color: green;
  border-bottom: 5px solid #9e8705;

  p {
    color: #e0e0e0;
  }
}

.option:hover {
  // background-color: #e0e0e0;
}

/* Adjust the styles as needed */
</style>
