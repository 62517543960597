<template>
  <div>
    <top-navbar :topNavbarType="['sidebar', 'login-button']"> </top-navbar>
    <div class="profile-container">
      <div class="user-info-div">
        <!-- <div class="image-container"> -->
        <img
          :src="profileImage"
          :style="{
            width: '45px',
            height: '45px',
            borderRadius: '50%',
            backgroundColor: 'crimson',
            objectFit: 'cover',
            objectPosition: 'center',
          }"
        />
        <div class="profile-pic" :style="{ marginLeft: '10px' }">
          <div :style="{ display: 'flex' }">
            <div
              class="edit-name"
              :style="{ paddingRight: '8px', marginBottom: '5px' }"
            >
              <p>{{ userData.username }}</p>
            </div>
          </div>
          <div class="phone">
            <p>{{ `+91 ${userData.phoneNumber}` }}</p>
          </div>
        </div>
      </div>
      <div
        class="menu-block"
        :style="{
          margin: '16px 0px',
          backgroundColor: 'white',
          borderRadius: '10px',
        }"
      >
        <!-- <div class="profile-items" @click="goTo('my-freels')">
          <p>My Creation</p>
        </div> -->
        <div class="profile-items" @click="goTo('my-orders')">
          <p>My Orders</p>
        </div>
        <!-- <div class="profile-items">
          <p>Favourites</p>
        </div> -->
        <div class="profile-items" @click="goTo('address-book')">
          <p>Address Book</p>
        </div>
        <!-- <div class="profile-items"></div> -->
      </div>
      <div
        class="menu-block"
        :style="{
          margin: '16px 0px',
          backgroundColor: 'white',
          borderRadius: '10px',
        }"
      >
        <div class="profile-items"><p>Logout</p></div>
      </div>
      <div>
        <button-comp
          type="button"
          :height="'38px'"
          :value="'Delete Account'"
          :color="'white'"
          :textColor="'crimson'"
          :style="{ flex: 1, marginTop: '8px' }"
          :showConfirmationModal="true"
          :textToShow="'Are you sure you want to say a goodbye to us? We have the best food in town for you!'"
          @clickEvent="
            () => {
              deleteAccount();
            }
          "
        ></button-comp>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile-component",
  components: {},
  data() {
    return {
      fileNameDisplay: "",
      resizedImageSrc: "",
      userData: {},
      profileImage: "",
    };
  },
  async mounted() {
    let userData = await this.getApiCall("/user/getUserInfo");
    this.userData = userData;
    this.profileImage = "";
    let r = `/images/profile-letters/${this.getFirstAlphabetCharacter(
      this.userData.username
    )}.png`;
    this.profileImage = r;
  },
  // computed: {
  // profileImage() {
  //   return `/images/profile-letters/${this.getFirstAlphabetCharacter(
  //     this.userData.username
  //   )}.png`;
  // },
  // },
  methods: {
    getFirstAlphabetCharacter(username) {
      // Find the first alphabetic character using regex
      const match = username?.match(/[a-zA-Z]/);
      // Convert the character to lowercase if found, otherwise return null
      return match ? match[0].toLowerCase() : null;
    },
    // fakeClick() {
    //   this.$refs.fileInput.click();
    // },
    async deleteAccount() {
      let reply = await this.postApiCall("/user/deleteAccount");
      if (reply === "ACCOUNT_DELETED") {
        this.logout();
      }
    },
    async logout() {
      try {
        window.plugins.OneSignal.logout();
      } catch (e) {
        console.log(e);
      }
      await localStorage.removeItem("addressData");
      await localStorage.removeItem("userData");
      this.$router.replace("/login");
    },
    handleFileChange(event) {
      const file = event.target.files[0]; // Get the selected file

      if (file) {
        this.profileImage = URL.createObjectURL(event.target.files[0]);
        const reader = new FileReader(); // Initialize FileReader

        // Set the onload function to handle the file once it's read
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result; // The file data is available in e.target.result

          // Once the image has been loaded, resize it
          img.onload = () => {
            this.resizeImage(img);
          };
        };

        // Start reading the file as a data URL
        reader.readAsDataURL(file);
      }
    },
    resizeImage(img) {
      // Create a canvas
      const canvas = window.document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set the desired width and height (e.g., scale down to 800px max width)
      const maxWidth = 800;
      const scaleFactor = maxWidth / img.width;
      canvas.width = maxWidth;
      canvas.height = img.height * scaleFactor; // Maintain the aspect ratio

      // Draw the image on the canvas with the new dimensions
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      // Get the resized image as a data URL (you can also use `canvas.toBlob()` for uploading)

      const self = this;
      canvas.toBlob(
        (blob) => {
          self.resizedImageBlob = blob; // Store the Blob to send to the backend
          self.resizedImageSrc = URL.createObjectURL(blob); // Preview the image

          self.uploadFileCall("/user/uploadProfilePic", {}, blob);
        },
        "image/jpeg",
        0.7
      ); // Use JPEG format with 70% quality
    },
    goTo(goToRoute) {
      switch (goToRoute) {
        case "my-freels":
          this.$router.push(`/my-freels`);
          break;
        case "my-orders":
          this.$router.push(`/my-orders`);
          break;
        case "address-book":
          this.$router.push(`/customer/add-address`);
          break;
        case "profile":
          this.$router.push(`profile`);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-container {
  margin: 1em 1em 0em 1em;

  p {
    font-family: "Montserrat" !important;
  }
}

.user-info-div {
  background-color: white;
  display: flex;
  padding: 16px;
  border-radius: 20px;
  //   width: 100px;
}

.profile-items {
  padding: 8px;
}

#fileInput {
  display: none; /* Hide the file input */
}
#uploadIcon {
  cursor: pointer;
  font-size: 40px; /* Adjust size as needed */
}

.pencil-icon {
  position: relative;
  bottom: -2px; /* Position it at the bottom-right corner */
  right: 20px;
  width: 34px; /* Adjust icon size */
  height: 34px;
  background-size: contain; /* Ensure the icon fits inside */
}
</style>
