<template>
  <div
    class="onboarding-carousel"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <div class="carousel-container" :style="carouselStyle">
      <div class="slide" v-for="(slide, index) in slides" :key="index">
        <img :src="slide.image" alt="Onboarding Slide" class="slide-image" />
        <h1
          :style="{
            textAlign: 'center',
            padding: '0px 25px',
            marginBottom: '40px',
            color: 'grey',
          }"
        >
          {{ slide.slideTopic }}
        </h1>
        <p :style="{ textAlign: 'center', padding: '0px 25px', color: 'grey' }">
          {{ slide.slideContent }}
        </p>
      </div>
    </div>
    <div class="carousel-controls">
      <button
        v-for="(slide, index) in slides"
        :key="index"
        :class="{ active: currentSlide === index }"
      ></button>
    </div>
    <div :style="{ height: '100px', paddingTop: '50px', width: '80%' }">
      <button-comp
        v-show="currentSlide === 3"
        @click="finishOnboarding()"
        :value="'Order Now!'"
      >
      </button-comp>
    </div>
  </div>
</template>

<script>
export default {
  name: "OnboardingCarousel",
  data() {
    return {
      currentSlide: 0,
      touchStartX: 0,
      touchEndX: 0,
      slides: [
        {
          image: "/images/onboarding/variety.svg",
          slideContent:
            "Explore a wide range of restaurants near you, from hidden gems to popular favorites.",
          slideTopic: "Discover Local Flavors",
        },
        {
          image: "/images/onboarding/quality.svg",
          slideContent:
            "From Indian to Continental, Chinese to Desserts—find your favorite cuisines in one place.",
          slideTopic: "Satisfy Every Craving",
        },
        {
          image: "/images/onboarding/low-price.svg",
          slideContent:
            "Enjoy mouthwatering meals at unbeatable prices with exclusive discounts and offers.",
          slideTopic: "Deals You’ll Love",
        },
        {
          image: "/images/onboarding/delivery.svg",
          slideContent:
            "Get your food delivered quickly, ensuring every bite is as fresh as it’s meant to be.",
          slideTopic: "Hot & Fresh, Fast",
        },
      ],
    };
  },
  computed: {
    carouselStyle() {
      return {
        transform: `translateX(-${this.currentSlide * 100}%)`,
        transition: "transform 0.5s ease-in-out",
      };
    },
  },
  methods: {
    goToSlide(index) {
      this.currentSlide = index;
    },
    handleButtonClick(action) {
      alert(`Button clicked: ${action}`);
    },
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.touchEndX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      const swipeDistance = this.touchStartX - this.touchEndX;

      if (swipeDistance > 50 && this.currentSlide < this.slides.length - 1) {
        // Swipe left
        this.currentSlide += 1;
      } else if (swipeDistance < -50 && this.currentSlide > 0) {
        // Swipe right
        this.currentSlide -= 1;
      }
    },
    async finishOnboarding() {
      await this.postApiCall("/user/finishOnboarding");

      let existingUserData = JSON.parse(await localStorage.getItem("userData"));

      let toStoreData = {
        authToken: existingUserData?.authToken,
        username: existingUserData?.username,
        userId: existingUserData?.userId,
        accessRights: existingUserData?.accessRights,
        onboardComplete: true,
      };
      try {
        await localStorage.setItem("userData", JSON.stringify(toStoreData));
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.onboarding-carousel {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.carousel-container {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  transform: translateX(calc(-100% * var(--current-slide)));
}

.slide {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
}

.slide-image {
  width: 80%;
  max-width: 300px;
  margin-bottom: 40px;
}

.carousel-controls {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.carousel-controls button {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-controls button.active {
  background-color: #d66f18;
}
</style>
