<template>
  <div class="top-navbar">
    <div class="left-section">
      <div v-if="topNavbarType.includes('sidebar')" class="">
        <img
          size="7"
          class="sidebar-menu-icon sidebar-img"
          @click="showSideMenu()"
          src="/images/menu.png"
        />
      </div>
      <div
        class="navbar-content"
        v-if="topNavbarType.includes('back-button')"
        @click="
          () => {
            backPressed();
          }
        "
      >
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.39866 1.40161L1.93466 7.88828L8.39866 14.3749"
            stroke="#303030"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div
        class="navbar-content"
        v-if="topNavbarType.includes('back-button-target')"
        @click="
          () => {
            backPressed();
          }
        "
      >
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.39866 1.40161L1.93466 7.88828L8.39866 14.3749"
            stroke="#303030"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="middle-section">
      <div v-if="topNavbarType.includes('app-name')" class="">
        <h2 class="navbar-app-name">
          {{ topNavbarData.title }}
        </h2>
      </div>
      <div
        v-if="
          topNavbarType.includes('toggle-status-button') &&
          hasAccessRight('RECIEVE_ORDER')
        "
        class=""
      >
        <div class="navbar-toggle-button">
          <h2>Status : &nbsp;</h2>
          <status-toggle></status-toggle>
        </div>
      </div>
      <div v-if="topNavbarType.includes('current-address')" class="">
        <div
          v-if="address"
          @click="
            () => {
              showAddAddressModal = true;
            }
          "
        >
          <h2 class="navbar-location-name solid">
            {{ addressText.addressName || addressText.add1 }}
          </h2>
          <!-- {{ addressText }} -->
          <h2 v-if="address?.addressName" class="navbar-location-address">
            {{
              `${address.buildingAddress} ${address.streetAddress}`.length > 32
                ? `${address.buildingAddress} ${address.streetAddress}`.substring(
                    0,
                    27
                  ) + "..."
                : `${address.buildingAddress} ${address.streetAddress}`
            }}
          </h2>
          <h2 v-else>{{ address.add2 }}</h2>
        </div>
        <div v-else>
          <h2
            @click="
              () => {
                showAddAddressModal = true;
              }
            "
          >
            Add Address
          </h2>
        </div>
      </div>
    </div>
    <div
      class="right-section"
      :style="{ marginLeft: 'auto', marginRight: '16px', display: 'flex' }"
    >
      <div v-if="topNavbarType.includes('love-sign')">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.463 3.99404C16.781 2.34904 14.44 3.01204 13.034 4.06804C12.458 4.50104 12.17 4.71804 12 4.71804C11.83 4.71804 11.542 4.50104 10.966 4.06804C9.56001 3.01204 7.21901 2.34904 4.53701 3.99404C1.01801 6.15304 0.222012 13.274 8.34001 19.284C9.88601 20.427 10.659 21 12 21C13.341 21 14.114 20.428 15.66 19.283C23.778 13.275 22.982 6.15304 19.463 3.99404Z"
            stroke="#303030"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div v-if="topNavbarType.includes('login-button') && userLoggedOut">
        <button-comp
          :value="'Login'"
          :width="'70px'"
          :height="'40px'"
          @click="
            () => {
              showLoginModal = true;
            }
          "
        />
      </div>
      <div
        v-if="topNavbarType.includes('share')"
        :style="{ marginLeft: '10px' }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.2806 9.97033L14.7806 2.47033C14.6758 2.36538 14.5422 2.29387 14.3967 2.26486C14.2512 2.23584 14.1004 2.25061 13.9633 2.30731C13.8263 2.36401 13.7091 2.46008 13.6266 2.58339C13.5441 2.70669 13.5001 2.85168 13.5 3.00002V6.78283C11.0681 6.99096 8.38219 8.18158 6.1725 10.0556C3.51188 12.3131 1.85532 15.2222 1.5075 18.2466C1.48032 18.4817 1.528 18.7194 1.64375 18.9259C1.75949 19.1324 1.93741 19.2971 2.15218 19.3966C2.36695 19.496 2.60764 19.5253 2.83997 19.48C3.07231 19.4348 3.28447 19.3175 3.44625 19.1447C4.4775 18.0469 8.14688 14.5753 13.5 14.2697V18C13.5001 18.1484 13.5441 18.2934 13.6266 18.4167C13.7091 18.54 13.8263 18.636 13.9633 18.6927C14.1004 18.7494 14.2512 18.7642 14.3967 18.7352C14.5422 18.7062 14.6758 18.6347 14.7806 18.5297L22.2806 11.0297C22.4209 10.8891 22.4997 10.6986 22.4997 10.5C22.4997 10.3014 22.4209 10.1109 22.2806 9.97033ZM15 16.1897V13.5C15 13.3011 14.921 13.1103 14.7803 12.9697C14.6397 12.829 14.4489 12.75 14.25 12.75C11.6175 12.75 9.05344 13.4372 6.62907 14.7938C5.39434 15.4878 4.2439 16.322 3.20063 17.28C3.74438 15.045 5.115 12.9197 7.14282 11.1994C9.31969 9.35346 11.9766 8.25002 14.25 8.25002C14.4489 8.25002 14.6397 8.171 14.7803 8.03035C14.921 7.8897 15 7.69893 15 7.50002V4.81127L20.6897 10.5L15 16.1897Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
    <choose-location-modal
      v-if="showAddAddressModal && $route.name !== 'AddAddressPage'"
      :isScrollable="true"
      @turnOffModal="
        () => {
          this.showAddAddressModal = false;
        }
      "
      @gotoAddAddressPage="gotoAddAddressPage"
    ></choose-location-modal>
    <LoginModal v-if="showLoginModal" @turnOfLogin="turnOfLogin" />
  </div>
</template>
<script>
import ChooseLocationModal from "../modals/ChooseLocationModal.vue";
import StatusToggle from "../StatusToggle.vue";
import LoginModal from "./../../pages/Login/LoginModal.vue";

export default {
  name: "TopNavbar",
  components: { ChooseLocationModal, StatusToggle, LoginModal },
  data() {
    return {
      showAddAddressModal: false,
      address: {},
      showLoginModal: false,
      userLoggedOut: false,
    };
  },
  props: {
    topNavbarType: {
      type: Array,
    },
    topNavbarData: {
      type: Object,
    },
  },
  computed: {
    addressText() {
      return this.address;
    },
  },
  watch: {
    addressPresent: {
      deep: true,
      handler: async function () {
        let currentAddress = await localStorage.getItem("addressData");
        if (currentAddress) {
          this.address = currentAddress;
          // return !!currentAddress;
        } else {
          this.address = await this.getAddress();
          // return !!currentAddress;
        }
      },
    },
  },
  mounted() {
    // const self = this;
    if (window.cordova) {
      document.addEventListener("backbutton", this.backPressed, false);
    } else {
      const self = this;
      window.addEventListener(
        "popstate",
        () => {
          self.$store.dispatch("resetOrders");
        },
        false
      );
    }
  },
  methods: {
    async checkForLogin() {
      let loginDetails = await localStorage.getItem("userData");
      if (loginDetails) {
        // this.showAddAddressDetailComp = true;
      } else {
        this.userLoggedOut = true;
        // this.showLoginModal = true;
      }
    },
    turnOfLogin() {
      this.showLoginModal = false;
    },
    turnOnLogin() {
      this.showLoginModal = true;
    },
    backPressed() {
      this.$router.go(-1);
      // if (!window.cordova) {
      this.$store.dispatch("resetOrders");
      // }
    },
    async gotoAddAddressPage() {
      this.showAddAddressModal = false;
      setTimeout(async () => {
        await this.$router.push("/customer/add-address");
      }, 200);
    },
    gotoPage(y) {
      this.$router.push(y);
    },
    showSideMenu() {
      this.$store.dispatch("setStatusBar", true);
    },
  },
  async created() {
    this.checkForLogin();

    let currentAddress = await localStorage.getItem("addressData");
    this.address = JSON.parse(currentAddress);

    let userData = JSON.parse(await localStorage.getItem("userData"));
    let onboardComplete = userData?.onboardComplete;

    if (
      !this.address &&
      !(
        this.hasAccessRight("RECIEVE_ORDER") ||
        this.hasAccessRight("CHANGE_ORDER_STATUS")
      ) &&
      this.$route.name !== "AddAddressPage" &&
      this.$route.name !== "DeliveryRestaurantMenu" &&
      onboardComplete === true
    ) {
      this.showAddAddressModal = true;
      this.address = await this.getAddress();
    }

    // this.getRestaurantDistance(this.);
  },
};
</script>
<style lang="scss">
.top-navbar {
  display: flex;
  z-index: 1000;
  background-color: #ffffff;
  width: 100%;
  height: 60px;
  top: 0;
  position: sticky;
  align-items: center;
  padding-left: 16px;
  box-shadow: 0px 2px 4px 0px rgba(215, 110, 25, 0.25);
}

.sidebar-img {
  max-width: 40px;
  max-height: 40px;
}

.navbar-toggle-button {
  font-family: "Roboto", "cursive" !important;
  // font-size: 30px;
  /* text-align: center; */
  margin-left: 16px;
  color: #7e685a;
  display: flex;
}

.navbar-app-name {
  font-family: "Inter" !important;
  // font-size: 30px;
  /* text-align: center; */
  margin-left: 16px;
  color: #303030;
}

.navbar-location-name {
  font-weight: 600;
}

.app-name-container {
  display: flex;
  justify-items: center;
}

.navbar-chat-name {
  font-family: "Roboto", "cursive" !important;
  // font-size: 30px;
  /* text-align: center; */
  margin-left: 16px;
  color: #7e685a;
}

.middle-section {
  margin-left: 10px;
}

.navbar-content {
  display: flex;
  flex-direction: row;

  img {
    width: 20px;
  }
}
</style>
