<template>
  <div class="container"></div>
</template>

<script>
export default {
  name: "creatorComponent",
  components: {},
  data() {
    return {};
  },
  async created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  margin: 16px 16px 16px 16px;
  display: flex;
}
</style>
