<template>
  <div>
    <top-navbar
      :topNavbarType="['back-button', 'app-name']"
      :topNavbarData="{ title: 'Edit Menu' }"
    ></top-navbar>
    <div class="table-detail-container">
      <div class="search-strip">
        <div class="search-strip-input">
          <input-field
            id="search"
            :bindedClass="'search'"
            placeholder="Search"
            @change-handler="getSearchedList"
          />
        </div>
        <div class="search-strip-button">
          <button-comp
            type="button"
            :height="'34px'"
            :value="'Add Menu Category'"
            :style="{ flex: 1, marginTop: '8px' }"
            @click="
              () => {
                showAddMenuCategoryModal = true;
              }
            "
            >Apply</button-comp
          >
        </div>
      </div>

      <div
        v-for="item in renderedMenuList"
        :key="item._id"
        class="menu-card-space"
      >
        <div class="menu-card">
          <div class="menu-item-card-top">
            <p class="menu-name">{{ item.name }}</p>
            <div class="action-button">
              <img
                class="action-edit"
                src="/images/pencil.png"
                alt=""
                @click="setPrefillDataAndEdit(item)"
              />
            </div>
          </div>
          <img class="item-image" :src="item.itemImage" />
          <p class="menu-description">
            {{ item.description }}
          </p>
          <div v-if="item.discountPrice" :style="{ display: 'flex' }">
            <p class="menu-item-price">₹ {{ item.discountPrice }}</p>
            &nbsp;
            <s class="menu-item-price">₹ {{ item.itemPrice }}</s>
          </div>
          <div v-else>
            <p class="menu-item-price">₹ {{ item.itemPrice }}</p>
          </div>
          <div :style="{ display: 'flex' }">
            <div
              :style="{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }"
            >
              <div>
                <toggle-switch
                  :status="item.status"
                  @change="changeItemStatus($event, item)"
                />
              </div>
              <div>
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style="display: none"
                  @change="handleFileUpload($event, item._id)"
                />
                <!-- Custom Button as Trigger -->
                <label
                  for="fileInput"
                  :style="{
                    cursor: 'pointer',
                    background: '#007BFF',
                    color: '#fff',
                    padding: '8px 16px',
                    borderRadius: '4px',
                    display: 'inline-block',
                  }"
                >
                  Upload
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <p>HOla</p> -->
      <div class="place-order-button">
        <div class="button-div">
          <ButtonComp
            class="corner-button"
            :value="'Add Menu Item'"
            :height="'40px'"
            :width="'100%'"
            :color="'crimson'"
            @clickEvent="turnEditOrderModalOn()"
          />
        </div>
      </div>
      <add-item-modal
        v-if="showAddItemModal"
        @editedObject="replaceEditedObject"
        @turnEditOrderModalOff="turnEditOrderModalOff"
        :prefillData="prefillData"
        :prefillTags="prefillTags"
      />
      <AddMenuCategoryModal
        v-if="showAddMenuCategoryModal"
        @turnCategoryModalOff="
          () => {
            showAddMenuCategoryModal = false;
          }
        "
      />
    </div>
  </div>
</template>
<script>
import moment from "moment-timezone";
import _ from "underscore";
import AddMenuCategoryModal from "../modals/AddMenuCategoryModal.vue";
import AddItemModal from "../modals/AddItemModal.vue";
// import PullToRefresh from "pulltorefreshjs";

export default {
  components: { AddItemModal, AddMenuCategoryModal },
  data() {
    return {
      menuList: [],
      showAddMenuCategoryModal: false,
      renderedMenuList: [],
      showAddItemModal: false,
      prefillData: {},
      prefillTags: [],
    };
  },
  props: {},
  created() {
    // PullToRefresh.destroyAll(true);
    this.getMenuListForDashboard();
  },
  watch: {},
  computed: {},
  methods: {
    async changeItemStatus(status, item) {
      await this.postApiCall("/admin/changeItemStatus", {
        status,
        itemId: item._id,
      });
    },
    async handleFileUpload(event, itemId) {
      const file = event.target.files[0];
      if (!file) {
        alert("No file selected!");
        return;
      }

      try {
        // Send the file to your backend
        const formData = new FormData();
        formData.append("file", file);
        formData.append("menuItemId", itemId);

        await this.uploadFile("/admin/uploadMenuImage", formData);
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload file");
      }
    },
    async getMenuListForDashboard() {
      const menuData = await this.getApiCall(
        "/admin/getEditableRestaurantMenu"
      );
      this.menuList = menuData;
      this.renderedMenuList = menuData;

      const menuFilters = await this.getApiCall(
        "/admin/getRestaurantFilterList"
      );
      this.prefillTags = menuFilters;
    },
    replaceEditedObject(editedObj) {
      let menuListItemIndex = _.findIndex(this.menuList, {
        _id: editedObj._id,
      });
      if (menuListItemIndex !== -1) {
        this.menuList[menuListItemIndex] = editedObj;
      }

      let renderedMenuListItemIndex = _.findIndex(this.renderedMenuList, {
        _id: editedObj._id,
      });

      if (renderedMenuListItemIndex !== -1) {
        this.renderedMenuList[renderedMenuListItemIndex] = editedObj;
      }
    },
    getSearchedList(phrase) {
      this.renderedMenuList = this.menuList;
      const regex = new RegExp(`${phrase}`, "g");

      let y = this.renderedMenuList.filter(
        (item) =>
          regex.test(item.name) ||
          (item?.itemTags && item.itemTags?.some((tag) => regex.test(tag)))
      );
      this.renderedMenuList = y;
    },
    setPrefillDataAndEdit(item) {
      this.prefillData = item;
      this.showAddItemModal = true;
    },
    formatTime(time) {
      return moment(time).format("LT");
    },
    turnEditOrderModalOff() {
      this.showAddItemModal = false;
      this.prefillData = null;
    },
    turnEditOrderModalOn() {
      this.showAddItemModal = true;
    },
  },
  // beforeUnmount() {
  //   PullToRefresh.init({
  //     mainElement: "body",
  //     onRefresh() {
  //       window.location.reload();
  //     },
  //   });
  // }
};
</script>
<style lang="scss" scoped>
.table-detail-container {
  margin: 16px 16px 0px 16px;

  .menu-card-space {
    margin-bottom: 16px;
  }

  .menu-card {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 10px;
    margin-bottom: 16px;

    .menu-item-card-top {
      display: flex;

      .menu-name {
        // font-size: 20px;
        margin-top: 13px;
      }

      .action-button {
        margin-left: auto;

        img {
          width: 25px;
          margin-left: auto;
        }
      }
    }

    .menu-description {
      // font-size: 12px;
      margin-top: 13px;
    }

    .menu-item-price {
      // font-size: 14px;
      margin-top: 13px;
    }

    .item-image {
      //   width: max-content;
      //   img {
      width: 100px;
      float: right;
      margin-left: 16px;
      //   }
    }
  }
}

.search-strip {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .search-strip-input {
    flex: 1;
    margin-right: 4px;
  }

  .search-strip-button {
    width: 25%;
  }
}

.place-order-button {
  position: fixed;
  bottom: 16px;
  width: calc(50% - 16px);
  right: 16px;
  // bottom: 16px;
}

.button-div {
  margin: 16px 0px 16px 0px;
  width: calc(100%);
}
</style>
